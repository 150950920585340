body > iframe {
    pointer-events: none;
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0.7;
    }
    50% {
        opacity: 1;
    }
}

.fade-in-out {
    animation: fadeInOut 3s ease-in-out infinite;
}
